<template>
  <v-container class="pa-4">
    <div v-if="selectedAsset">
      <v-container>
        <v-row>
          <v-col lg="9">
            <CurrentlySelectedAsset />
          </v-col>
          <v-col lg="3" align-self="center">
            <StreamDateSelector />
          </v-col>
        </v-row>
      </v-container>
      <v-divider class="my-6"></v-divider>
      <SingleObjectEntry :objectArray="assetMessages" />
    </div>
    <SelectAssetFirst v-else />
  </v-container>
</template>

<script>
import SelectAssetFirst from "@/components/SelectAssetFirst";
import CurrentlySelectedAsset from "@/components/CurrentySelectedAsset";
import SingleObjectEntry from "@/components/SingleObjectEntry";
import StreamDateSelector from "@/components/StreamDateSelector";

export default {
  name: "AssetStream",
  components: {
    SelectAssetFirst,
    CurrentlySelectedAsset,
    SingleObjectEntry,
    StreamDateSelector
  },
  computed: {
    selectedAsset() {
      return this.$store.state.selectedAsset.objectId;
    },
    assetMessages() {
      return this.$store.state.selectedAsset.messages;
    }
  }
};
</script>
