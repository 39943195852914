<template>
  <div
    v-if="objectArray.length"
    class="d-flex flex-column-reverse"
    style="max-width:90%; margin:0 auto;"
  >
    <v-card
      flat
      outlined
      class="mb-8 pa-4"
      v-for="(object, index) in objectArray"
      :key="index"
    >
      <div>
        <h5>PARSED</h5>
        <v-card
          flat
          class="mb-2 pa-3"
          v-for="(message, index) in object.parsed"
          :key="index"
        >
          {{ message }}
        </v-card>
      </div>
      <div class="mt-8">
        <h5>RAW</h5>
        {{ object.raw }}
      </div>
    </v-card>
  </div>
  <h3 v-else>{{ $t("waiting") }}</h3>
</template>

<script>
export default {
  name: "SingleObjectEntry",
  props: {
    objectArray: {
      type: Array,
      default() {
        return [];
      }
    }
  }
};
</script>
